// Functionality
import { useState, useContext } from 'react'
import axios from 'axios'
import UserProvider from '@/Contexts/UserProvider'
import { useRouter } from 'next/router'
import Link from 'next/link'

// Layout
import { Modal, Form, Col, Row } from 'react-bootstrap'
import providerData from './providerData'
import {
    HorizontalSpacer,
    SubLogoSpan,
    AddPadding,
    AuthButtonStyle,
    AuthIcon,
    VerticalOrStyle,
    HorizontalOrStyle,
    Label,
    CheckMarkText,
    LoginButton,
    Disclaimer,
    ValidationError,
} from './styles'

import { sendEvent } from '@/Lib/gtm'

import { useFormik } from 'formik'
import * as Yup from 'yup'

const title = (name) => name.charAt(0).toUpperCase() + name.slice(1)

const AuthButton = ({ label, img, color, href, name }) => {
    const baseUrl = process.env.FRONT_END_URL
    return (
        <AuthButtonStyle
            className="z-depth-1"
            color={color}
            onClick={() => {
                if (href) {
                    sendEvent('login_register_button_clicked', {
                        event_data: { category: 'acquisition', label: `third_party_${name}` },
                    })
                    window.location = `${baseUrl}/api/${href}`
                }
            }}
        >
            <AuthIcon img={`${baseUrl}/${img}`} color={color} />
            <div className="label">{label}</div>
        </AuthButtonStyle>
    )
}

const EmailSection = ({ setShow }) => {
    const { updateUserState } = useContext(UserProvider.context)

    const router = useRouter()

    const onSubmit = (e) => {
        e.preventDefault()
        if (formik.isValid) {
            sendEvent('login_register_button_clicked', { event_data: { category: 'acquisition', label: `email` } })
            axios
                .post('/api/auth/register_login', formik.values, { withCredentials: true })
                .then((res) => {
                    updateUserState()
                    const signupVia = res.data.signupVia
                    if (signupVia) {
                        sendEvent('signup', {
                            event_data: { category: 'acquisition', label: `third_party_${signupVia}` },
                        })
                    }
                    const redirectURL = new URL(res.data.data)
                    router.push(redirectURL.pathname + redirectURL.search)

                    setShow(false)
                })
                .catch((err) => {
                    formik.setErrors({
                        password:
                            'Das Passwort ist für diese E-Mail nicht korrekt. Bitte überprüfe Passwort und E-Mail.',
                    })
                })
        } else {
            alert(`Bitte Eingabe überprüfen! Folgende Fehler liegen vor: ${Object.values(formik.errors)}`)
        }
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            marketingOptIn: false,
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Unzulässige E-Mail Adresse').required('E-Mail Adresse erforderlich'),
            password: Yup.string().required('Passwort erforderlich'),
            marketingOptIn: Yup.boolean(),
        }),
    })

    return (
        <Form noValidate onSubmit={onSubmit}>
            <Form.Group controlId="email">
                <Label>Email Adresse:</Label>
                {formik.touched.email && formik.errors.email ? (
                    <ValidationError>{formik.errors.email}</ValidationError>
                ) : null}
                <Form.Control type="email" {...formik.getFieldProps('email')} />
            </Form.Group>
            <Form.Group controlId="password">
                <Label>Passwort:</Label>
                {formik.touched.password && formik.errors.password ? (
                    <ValidationError>{formik.errors.password}</ValidationError>
                ) : null}
                <Form.Control type="password" {...formik.getFieldProps('password')} />
                <Form.Text>
                    <Link href="/profile/password/request">
                        <a target="_blank">Passwort vergessen?</a>
                    </Link>{' '}
                </Form.Text>
            </Form.Group>
            <Form.Group controlId="marketingOptIn">
                <CheckMarkText
                    type="checkbox"
                    label="Hiermit willige ich ein, dass ViTeach mich gelegentlich über neue Inhalte und Funktionen informieren darf"
                    checked={formik.values.marketingOptIn}
                    onChange={formik.handleChange}
                />
            </Form.Group>
            <div className="d-none d-lg-block" style={{ marginTop: '50px' }} />
            <LoginButton
                className="z-depth-1"
                onClick={
                    (e) => onSubmit(e)
                    // formik.handleSubmit
                }
            >
                ANMELDEN / EINLOGGEN
            </LoginButton>
            {/* This input none seems to be required to get get submit on enter working: https://github.com/formium/formik/issues/1418 */}
            <input style={{ display: 'none' }} type="submit" />
        </Form>
    )
}

const HorizontalOr = () => {
    return (
        <div className="d-block d-lg-none">
            <HorizontalOrStyle>
                <div className="outer" />
                <div className="inner">alternativ</div>
                <div className="outer" />
            </HorizontalOrStyle>
            <HorizontalSpacer />
        </div>
    )
}

const VerticalOr = () => {
    return (
        <div className="d-none d-lg-block">
            <VerticalOrStyle>
                <div className="outer" />
                <div className="inner">alternativ</div>
                <div className="outer" />
            </VerticalOrStyle>
        </div>
    )
}

const SignupLoginModal = ({ show, setShow }) => {
    const [showEmailSection, setShowEmailSection] = useState(false)

    return (
        <Modal show={show} onHide={() => setShow(false)} size="lg" style={{ padding: '20px 0' }}>
            <Row style={{ marginTop: '40px', marginBottom: '40px' }}>
                <Col xs={12} lg={6} style={{ position: 'relative' }}>
                    <AddPadding>
                        <img
                            alt="company logo"
                            src="/assets/icons/viteach_logo.svg"
                            height="70px"
                            style={{ display: 'block', margin: 'auto' }}
                        />
                        <HorizontalSpacer />
                        <SubLogoSpan>
                            Jetzt <b>kostenlos</b> anmelden / einloggen:
                        </SubLogoSpan>
                        <HorizontalSpacer />
                        {providerData.map((provider) => {
                            return (
                                <AuthButton {...provider} label={`Mit ${title(provider.name)}`} key={provider.name} />
                            )
                        })}
                        <HorizontalSpacer />
                        <HorizontalOr />
                        <VerticalOr />
                    </AddPadding>
                </Col>
                <Col xs={12} className="d-block d-lg-none">
                    <AddPadding>
                        {!showEmailSection ? (
                            <div onClick={() => setShowEmailSection(true)}>
                                <AuthButton
                                    {...{
                                        label: 'Mit Email',
                                        img: 'assets/icons/email_grey.png',
                                        color: '#C3CED6',
                                    }}
                                />
                            </div>
                        ) : (
                            <EmailSection setShow={setShow} />
                        )}
                    </AddPadding>
                </Col>
                <Col lg={6} className="d-none d-lg-block">
                    <AddPadding style={{ marginTop: '50px' }}>
                        <EmailSection setShow={setShow} />
                    </AddPadding>
                </Col>
                <AddPadding>
                    <Disclaimer>
                        Durch die Anmeldung stimmst Du unserer{' '}
                        <Link href="/data_privacy">
                            <a target="_blank">Datenschutzerklärung</a>
                        </Link>{' '}
                        und unseren{' '}
                        <Link href="/terms_and_conditions">
                            <a target="_blank">Geschäftsbedingungen</a>
                        </Link>{' '}
                        zu.
                    </Disclaimer>
                </AddPadding>
            </Row>
        </Modal>
    )
}

export default SignupLoginModal
