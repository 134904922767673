const providerData = [
    {
        img: 'assets/icons/google_new_transparent.png',
        name: 'google',
        href: 'auth/google',
        alt: 'google-icon',
        color: '#fff',
        txt: 'Login with Google',
    },
    {
        img: 'assets/icons/facebook_color.png',
        name: 'facebook',
        href: 'auth/facebook',
        alt: 'facebook-icon',
        color: '#3B5899',
        txt: 'Login with Facebook',
    },
    {
        img: 'assets/icons/spotify_color.png',
        name: 'spotify',
        href: 'auth/spotify',
        alt: 'spotify-icon',
        color: '#1EB954',
        txt: 'Login with Spotify',
    },
    {
        img: 'assets/icons/twitch_color.png',
        name: 'twitch',
        href: 'auth/twitch',
        alt: 'twitch-icon',
        color: '#5F3BAD',
        txt: 'Login with Twitch',
    },
]

export default providerData
