import styled from 'styled-components'
import { device } from '@/Components/styles'
import { Form } from 'react-bootstrap'

const fontsize = `font-size: 16px; ${device.sm} {font-size: 20px}`
const fontsize_small = `font-size: 11px; ${device.sm} {font-size: 15px}`

export const ValidationError = styled.div`
    ${fontsize_small};
    color: #1575e2;
    font-weight: 900;
`

export const HorizontalSpacer = styled.div`
    height: 10px;
    ${device.sm} {
        height: 20px;
    }
`

export const AddPadding = styled.div`
    width: 90%;
    margin: 0 5%;
`

export const SubLogoSpan = styled.span`
    word-wrap: break-word;
    color: #5b6b79;
    text-align: center;
    display: block;
    ${fontsize}
`

export const VerticalOrStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90%;
    margin: 0 5%;
    position: absolute;
    top: 50%;
    right: -5%;
    -ms-transform: translate(+ 50%; -50%);
    transform: translate(+50%, -50%);

    .outer {
        flex: 11;
        height: 1px;
        border: 1px solid #5b6b79;
    }

    .inner {
        flex: 10;
        display: flex;
        align-items: center;
        font-size: 20px;
        transform: rotate(-90deg);
    }
`


export const AuthButtonStyle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 2px;
    margin: 6px 0;

    ${device.sm} {
        margin: 13px 0;
    }
    cursor: pointer;


    .label {
        margin-left: 20px;
        ${fontsize};
        font-weight: 900;
        width: 100%;
    }

    &:hover {
        background: #fafafa;
    }
`

export const AuthIcon = ({ img, color }) => {
    return (
        <AuthIconContainer color={color}>
            <AuthIconInner img={img} />
        </AuthIconContainer>
    )
}

const AuthIconContainer = styled.div`
    margin-left: 15px;
    width: 46px;
    height: 46px;
    padding: 6px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    ${device.sm} {
        width: 50px;
        height: 50px;
        padding: 6px;
    }
`

const AuthIconInner = styled.div`
    width: 34px;
    height: 34px;
    ${device.sm} {
        width: 36px;
        height: 36px;
    }
    background: ${(props) => `url("${props.img}") no-repeat center center /100%`};
`

export const HorizontalOrStyle = styled.div`
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 5%;

    .outer {
        flex: 3;
        height: 1px;
        border: 1px solid #5b6b79;
    }

    .inner {
        flex: 2;
        text-align: center;
        ${fontsize}
    }
`

export const Label = styled(Form.Label)`
    margin-bottom: 0.25rem;
    font-weight: 900;
    color: #e28215;
    ${fontsize}
`

export const CheckMarkText = styled(Form.Check)`
    ${fontsize_small}
`

export const LoginButton = styled.div`
    cursor: pointer;
    width: 100%;
    min-height: 40px;
    ${device.sm} {
        min-height: 56px;
    }
    background-color: #e28215;
    color: white;
    font-size: 20px;
    ${device.sm} {
        font-size: 26px;
    }
    border-radius: 2px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
    padding: 0px 10px;

    &:hover {
        background: white;
        color: #e28215;
    }
`

export const Disclaimer = styled.div`
    margin-top: 10px;
    padding: 0 6px;
    ${fontsize_small}
`
