import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import { useRouter } from 'next/router'

import usePrevious from '@/Hooks/usePrevious'

const context = createContext(null)

const UserProvider = ({ children, sentry }) => {
    const router = useRouter()
    const [user, setUser] = useState({
        data: {},
        errors: 'No user data yet',
    })

    const { data: { _id: loggedInUserId, profilePicture } = {} } = user

    const prevData = usePrevious({ loggedInUserId, profilePicture })

    useEffect(() => {
        if (prevData?.loggedInUserId && prevData?.profilePicture !== profilePicture) {
            updateUserBackend()
        }
    }, [prevData, loggedInUserId, profilePicture])

    const updateUserState = () => {
        axios
            .get('/api/user/me', { withCredentials: true })
            .then((res) => {
                const {
                    data,
                    data: { errors: errors },
                } = res
                setUser({ data, errors })
                sentry.configureScope(function (scope) {
                    scope.setUser({ id: data._id })
                })
            })
            .catch((err) => {
                setUser({ data: {}, errors: `Error when logging in: ${err}` })
            })
    }

    const updateUserBackend = () => {
        axios
            .post('/api/user/update', user.data, { withCredentials: true })
            .then((res) => {
                const {
                    data,
                    data: { errors: errors },
                } = res
                setUser({ data, errors })
            })
            .catch((err) => {
                setUser({ data: {}, errors: `could not update with error: ${err}` })
            })
    }

    const logout = () => {
        axios
            .get('/api/user/logout')
            .then(() => {
                updateUserState()
                router.push('/')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const deleteUser = () => {
        axios
            .get('/api/user/delete')
            .then(() => {
                router.push('/')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const fetchReferrals = () => {
        axios
            .get('/api/user/fetch_referrals')
            .then((res) => {
                setUser((currUser) => {
                    currUser.data.validReferrals = res.data.referredUsers
                    return { ...currUser }
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(updateUserState, [])

    return (
        <context.Provider
            value={{
                user,
                loggedInUserId,
                updateUserState,
                updateUserBackend,
                logout,
                deleteUser,
                setUser,
                fetchReferrals,
            }}
        >
            {children}
        </context.Provider>
    )
}

UserProvider.context = context

export default UserProvider
